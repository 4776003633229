<template>
  <DefaultLayout class="frontend">
    <password-reset class="max-w-[79rem] p-12 mx-auto my-16 bg-white" />
  </DefaultLayout>
</template>

<script>
import PasswordReset from '@/components/PasswordReset.vue';

export default {
  components: {
    PasswordReset,
  },
};
</script>
