<template>
  <div>
    <h2 class="text-4xl">{{ $t('resetPassword.title') }}</h2>
    <h3 class="text-4xl">{{ $t('resetPassword.subTitle') }}</h3>
    <form class="mt-10">
      <label-field
        :label="$t('resetPassword.emailLabel')"
        is-block
        :label-class="[]"
      >
        <input
          v-model.trim="$v.email.$model"
          class="w-[26.25rem] form-input"
          required
          @blur="$v.email.$touch"
        />
      </label-field>
      <validation-text v-if="$v.email.$error" class="mt-1">
        {{ $t('resetPassword.emailRequired') }}
      </validation-text>
      <div class="flex items-center justify-between mt-6">
        <button
          class="btn btn--primary"
          :disabled="$v.$invalid"
          @click.prevent="validateToken"
        >
          {{ $t('resetPassword.submitButtonText') }}
        </button>
      </div>
    </form>
    <div class="mt-6">
      <router-link class="text-blue-primary" :to="{ name: 'login' }">
        {{ $t('forgotPassword.backToLogin') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import ValidationText from '@/components/ValidationText.vue';
import { required } from 'vuelidate/lib/validators';
import { checkResetToken } from '@/services/auth';
import LabelField from './LabelField';

export default {
  components: {
    LabelField,
    ValidationText,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      email: '',
    };
  },
  validations: {
    email: {
      required,
    },
  },
  methods: {
    async validateToken() {
      try {
        const response = await checkResetToken(this.token, this.email);
        if (!response.valid) {
          this.$notify({
            type: 'error',
            text:
              e.response?.data?.message ||
              this.$t('notifications.checkResetTokenError').toString(),
          });
          this.redirectToForgotPassword();
        } else {
          this.$emit('success', this.email);
        }
      } catch (e) {
        this.$notify({
          type: 'error',
          text:
            e.response?.data?.message ||
            this.$t('notifications.checkResetTokenError').toString(),
        });
        this.redirectToForgotPassword();
      }
    },
    redirectToForgotPassword() {
      this.$emit('redirect');
    },
  },
};
</script>
