<template>
  <div>
    <h2 class="text-4xl">{{ $t('resetPassword.title') }}</h2>
    <div class="mt-6">
      <validation-text v-if="passwordError" class="error-text">
        {{ passwordError }}
      </validation-text>

      <validation-text v-else-if="!isPasswordSet" class="error-text">
        {{ $t('resetPassword.passwordRequired') }}
      </validation-text>

      <validation-text v-else-if="!isPasswordValid" class="error-text">
        {{ $t('resetPassword.passwordInvalid') }}
      </validation-text>

      <validation-text v-else-if="!isSamePassword" class="error-text">
        {{ $t('resetPassword.passwordsNotMatching') }}
      </validation-text>
    </div>
    <form class="mt-6">
      <forge-password
        v-model.trim.lazy="$v.password.$model"
        class="mt-4"
        :value="password"
        :label="$t('resetPassword.passwordLabel')"
        :placeholder="$t('resetPassword.password')"
        is-block
        required
        @blur="onPasswordBlur"
      />

      <forge-password
        v-model.trim.lazy="$v.passwordConfirm.$model"
        class="mt-4"
        :value="passwordConfirm"
        :label="$t('resetPassword.passwordConfirm')"
        :placeholder="$t('resetPassword.passwordConfirm')"
        is-block
        required
        @blur="$v.passwordConfirm.$touch"
      />

      <div class="flex items-center justify-between mt-6">
        <button
          class="btn btn--primary"
          :disabled="$v.$invalid && isPasswordValid"
          @click.prevent="doPasswordReset"
        >
          {{ $t('resetPassword.changePassword') }}
        </button>
      </div>
    </form>
    <div class="mt-6">
      <router-link class="text-blue-primary" :to="{ name: 'login' }">
        {{ $t('forgotPassword.backToLogin') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import ValidationText from '@/components/ValidationText.vue';
import { required, sameAs } from 'vuelidate/lib/validators';
import { resetPassword } from '@/services/auth';
import ForgePassword from '@/components/Password.vue';

export default {
  components: {
    ValidationText,
    ForgePassword,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      password: '',
      passwordConfirm: '',
      passwordError: '',
    };
  },
  validations: {
    password: {
      required,
    },
    passwordConfirm: {
      required,
      sameAs: sameAs('password'),
    },
  },
  computed: {
    isPasswordValid() {
      if (this.password.length > 0) {
        const regex = /^(?=.*[A-Z])(?=.*\d).{6,}$/;
        return regex.test(this.password);
      }
      return true;
    },
    isPasswordSet() {
      if (this.$v.password.$error) {
        return false;
      }
      if (!this.$v.password.required && this.$v.password.$dirty) {
        return false;
      }
      return true;
    },
    isSamePassword() {
      if (!this.$v.passwordConfirm.sameAs && this.$v.passwordConfirm.$dirty) {
        return false;
      }
      return true;
    },
  },
  methods: {
    async doPasswordReset() {
      try {
        await resetPassword(this.password, this.token, this.email);
        this.$notify({
          type: 'success',
          text: this.$t('notifications.resetPasswordSuccess').toString(),
        });
        this.$router.push({ name: 'login' });
      } catch (e) {
        this.handleError(e);
      }
    },
    handleError(error) {
      if (error === 'token is invalid') {
        this.$notify({
          type: 'error',
          text:
            e.response?.data?.message ||
            this.$t('notifications.checkResetTokenError').toString(),
        });
        this.redirectToForgotPassword();
      } else {
        this.passwordError = error.replace(/\[|\]|'/gi, '');
        this.passwordError = this.passwordError.replace(/,/gi, '');
      }
    },
    redirectToForgotPassword() {
      this.$emit('redirect');
    },
    onPasswordBlur() {
      this.password.$touch;
      this.passwordError = '';
    },
  },
};
</script>
